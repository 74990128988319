import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Cleanup() {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear all localStorage
    localStorage.clear();
    
    // Log for confirmation
    console.log('LocalStorage cleared');
    
    // Redirect to login page after cleanup
    navigate('/');
  }, [navigate]);

  return (
    <div className="cleanup-message">
      Cleaning up and redirecting...
    </div>
  );
}

export default Cleanup; 