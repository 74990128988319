import React, { useState, useEffect } from 'react';
import { initSDK, init, receive, balance, unlock, lock, sendAsync, claim } from './arkSDK';

function Freelance() {
  const [balanceData, setBalanceData] = useState({ offchain: 0, onchain: { spendable: 0, locked: 0 } });
  const [logs, setLogs] = useState([]);
  const [formData, setFormData] = useState({
    aspUrl: localStorage.getItem('aspUrl') || 'https://insiders.signet.arklabs.to',
    password: localStorage.getItem('password') || '',
    privateKey: localStorage.getItem('nsec') || '',
    settlePassword: '',
  });
  const [isSDKReady, setIsSDKReady] = useState(false);
  const [sdkError, setSDKError] = useState(null);
  const [isWalletInitialized, setIsWalletInitialized] = useState(false);

  // Initialize SDK when component mounts
  useEffect(() => {
    const loadSDK = async () => {
      try {
        setSDKError(null);
        const success = await initSDK();
        if (success) {
          logMessage("SDK initialized successfully");
          setIsSDKReady(true);
        }
      } catch (err) {
        const errorMessage = err.message || 'Unknown error initializing SDK';
        setSDKError(errorMessage);
        logMessage("SDK initialization error: " + errorMessage);
        console.error('SDK Error:', err);
      }
    };

    loadSDK();
  }, []);

  // Separate useEffect for wallet initialization
  useEffect(() => {
    const initializeWallet = async () => {
      if (isSDKReady && formData.aspUrl && formData.privateKey) {
        try {
          await initWallet();
        } catch (err) {
          logMessage("Auto-init error: " + err.message);
        }
      }
    };

    initializeWallet();
  }, [isSDKReady]);

  const logMessage = (message) => {
    setLogs(prev => [...prev, message]);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [id]: value
    }));
  };

  // Initialize wallet
  const initWallet = async () => {
    if (!isSDKReady) {
      logMessage("Init error: SDK not initialized yet");
      return;
    }

    try {
      const { aspUrl, password, privateKey } = formData;
      if (!aspUrl) {
        logMessage("Init error: asp url is required");
        return;
      }

      const chain = "bitcoin";
      const walletType = "singlekey";
      const clientType = "rest";
      const explorerUrl = "";

      // Log all arguments for debugging
      console.log('Init args:', {
        walletType,
        clientType,
        aspUrl,
        privateKey,
        password: password || '', // Ensure password is never undefined
        chain,
        explorerUrl
      });

      await init(
        walletType,
        clientType,
        aspUrl,
        privateKey,
        password || '', // Ensure password is never undefined
        chain,
        explorerUrl
      );
      logMessage("wallet initialized and connected to ASP");
      setIsWalletInitialized(true);
    } catch (err) {
      logMessage("Init error: " + err.message);
      setIsWalletInitialized(false);
    }
  };

  // Get receive addresses
  const receiveAddresses = async () => {
    try {
      const addresses = await receive();
      logMessage("Offchain address: " + addresses.offchainAddr);
      logMessage("Boarding address: " + addresses.boardingAddr);
    } catch (err) {
      logMessage("Receive error: " + err.message);
    }
  };

  // Get balance
  const getBalance = async () => {
    try {
      const bal = await balance(false);
      logMessage("Offchain balance: " + bal.offchainBalance);
      logMessage("Onchain balance: ");
      logMessage("  Spendable: " + bal.onchainBalance.spendable);
      logMessage("  Locked: " + bal.onchainBalance.locked);
      setBalanceData({
        offchain: bal.offchainBalance,
        onchain: {
          spendable: bal.onchainBalance.spendable,
          locked: bal.onchainBalance.locked
        }
      });
    } catch (err) {
      logMessage("Balance error: " + err.message);
    }
  };

  // Claim VTXOs
  const claimVtxos = async () => {
    const { settlePassword } = formData;
    if (!settlePassword) {
      logMessage("Claim error: password is required");
      return;
    }
    
    try {
      await unlock(settlePassword);
      const txID = await claim();
      logMessage("Claimed money with tx ID: " + txID);
    } catch (err) {
      logMessage("Claim error: " + err.message);
    } finally {
      await lock(settlePassword);
    }
  };

  return (
    <div className="freelance-dashboard">
      <h2>Freelance Dashboard</h2>
      
      {sdkError && (
        <div className="error-message">
          SDK Initialization Error: {sdkError}
        </div>
      )}
      
      <div className="wallet-section">
        <h3>Wallet</h3>
        <div className="init-section">
          <button 
            onClick={initWallet}
            disabled={!isSDKReady}
          >
            {isSDKReady ? (isWalletInitialized ? 'Re-Init' : 'Init') : sdkError ? 'SDK Failed' : 'Loading SDK...'}
          </button>
          <input
            type="text"
            id="aspUrl"
            placeholder="ASP URL"
            value={formData.aspUrl}
            onChange={handleInputChange}
          />
          <input
            type="password"
            id="password"
            placeholder="password (optional)"
            value={formData.password}
            onChange={handleInputChange}
          />
          <input
            type="text"
            id="privateKey"
            placeholder="nsec"
            value={formData.privateKey}
            onChange={handleInputChange}
          />
        </div>

        <div className="actions-section">
          <button onClick={receiveAddresses} disabled={!isWalletInitialized}>Receive</button>
          <button onClick={getBalance} disabled={!isWalletInitialized}>Balance</button>

          <div className="settle-section">
            <button onClick={claimVtxos} disabled={!isWalletInitialized}>Claim</button>
            <input
              type="password"
              id="settlePassword"
              placeholder="password"
              value={formData.settlePassword}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>

      <div className="logs-section">
        <h3>Logs</h3>
        <textarea
          rows="20"
          cols="80"
          readOnly
          value={logs.join('\n')}
        />
      </div>
    </div>
  );
}

export default Freelance; 