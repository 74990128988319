import { PubkyClient, Keypair, PublicKey } from '@synonymdev/pubky';
import { hexToBytes } from '@noble/hashes/utils';

class PubkyService {
  constructor() {
    this.client = new PubkyClient();
    this.homeserver = PublicKey.from("8pinxxgqs41n4aididenw5apqp1urfmzdztr8jt4abrkdn435ewo");
    this.isInitialized = false;
  }

  async initialize(privateKeyHex) {
    try {
      if (!privateKeyHex) {
        throw new Error('Private key is required');
      }

      // Convert hex private key to Keypair
      const secretKey = hexToBytes(privateKeyHex);
      this.keypair = Keypair.fromSecretKey(secretKey);
      
      // Sign up or sign in
      try {
        await this.client.signup(this.keypair, this.homeserver);
        console.log('Pubky signup successful');
        
        // Create initial directory structure
        await this.createInitialStructure();
      } catch (err) {
        console.log('Attempting signin after signup failure:', err.message);
        // If already signed up, try signing in
        await this.client.signin(this.keypair);
        console.log('Pubky signin successful');
      }

      this.isInitialized = true;
      return true;
    } catch (err) {
      this.isInitialized = false;
      this.keypair = null;
      console.error('Pubky initialization error:', err);
      throw new Error(`Failed to initialize Pubky: ${err.message}`);
    }
  }

  async createInitialStructure() {
    try {
      const publicKey = this.keypair.publicKey();
      const directoryUrl = `pubky://${publicKey.z32()}/pub/payroll/`;
      
      // Create an empty index file to initialize the directory
      const indexUrl = `${directoryUrl}index.json`;
      const emptyIndex = new Uint8Array(JSON.stringify({ initialized: true }).split('').map(c => c.charCodeAt(0)));
      await this.client.put(indexUrl, emptyIndex);
      
      console.log('Initial directory structure created');
    } catch (err) {
      console.warn('Error creating initial structure:', err);
      // Don't throw - this might fail if directory already exists
    }
  }

  checkInitialized() {
    if (!this.isInitialized || !this.keypair) {
      throw new Error('Pubky service not initialized. Call initialize() first.');
    }
  }

  async storePayrollFile(payrollData) {
    try {
      this.checkInitialized();

      if (!payrollData) {
        throw new Error('Payroll data is required');
      }

      const timestamp = Date.now();
      const publicKey = this.keypair.publicKey();
      const url = `pubky://${publicKey.z32()}/pub/payroll/${timestamp}.json`;
      
      // Use TextEncoder instead of Buffer
      const encoder = new TextEncoder();
      const body = encoder.encode(JSON.stringify(payrollData));
      
      await this.client.put(url, body);
      console.log('Payroll file stored successfully:', url);
      return url;
    } catch (err) {
      console.error('Error storing payroll file:', err);
      throw new Error(`Failed to store payroll file: ${err.message}`);
    }
  }

  async getPayrollFile(url) {
    try {
      if (!url) {
        throw new Error('URL is required');
      }

      console.log('Fetching file from:', url); // Debug log
      const response = await this.client.get(url);
      console.log('Raw response:', response); // Debug log

      if (!response) {
        console.warn('File not found:', url);
        throw new Error('File not found');
      }

      // Use TextDecoder to parse the response
      const decoder = new TextDecoder();
      const text = decoder.decode(response);
      console.log('Decoded text:', text); // Debug log

      const parsed = JSON.parse(text);
      console.log('Parsed record:', parsed); // Debug log
      return parsed;
    } catch (err) {
      console.error('Error retrieving payroll file:', err);
      if (err.message === 'File not found') {
        return null;
      }
      throw new Error(`Failed to retrieve payroll file: ${err.message}`);
    }
  }

  async listPayrollFiles() {
    try {
      this.checkInitialized();

      const publicKey = this.keypair.publicKey();
      const prefix = `pubky://${publicKey.z32()}/pub/payroll/`;
      
      console.log('Listing files with prefix:', prefix); // Debug log
      const files = await this.client.list(prefix);
      console.log('Raw files list:', files); // Debug log
      
      // Filter out the index.json file
      const filteredFiles = files.filter(file => !file.endsWith('index.json'));
      console.log('Filtered files:', filteredFiles); // Debug log
      
      return filteredFiles;
    } catch (err) {
      // If we get a 404, it means no files exist yet
      if (err.message && err.message.includes('404')) {
        console.log('No files found (404)'); // Debug log
        return [];
      }
      console.error('Error listing payroll files:', err);
      throw new Error(`Failed to list payroll files: ${err.message}`);
    }
  }

  async cleanup() {
    try {
      if (this.isInitialized && this.keypair) {
        const publicKey = this.keypair.publicKey();
        await this.client.signout(publicKey);
      }
    } catch (err) {
      console.error('Error during Pubky cleanup:', err);
    } finally {
      this.isInitialized = false;
      this.keypair = null;
    }
  }
}

export const pubkyService = new PubkyService();