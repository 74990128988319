import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { nip19 } from 'nostr-tools';
import { bytesToHex, hexToBytes } from '@noble/hashes/utils';
import { pbkdf2 } from '@noble/hashes/pbkdf2';
import { sha256 } from '@noble/hashes/sha256';
import { encrypt } from './encryption';

function Login() {
  const [nsec, setNsec] = useState('');
  const [password, setPassword] = useState('');
  const [isCompany, setIsCompany] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Derive encryption key from password
  const deriveKey = (password, salt) => {
    // Convert password to bytes
    const passwordBytes = new TextEncoder().encode(password);
    // Use PBKDF2 with SHA-256 and 100,000 iterations
    const derivedKey = pbkdf2(sha256, passwordBytes, salt, { c: 100000, dkLen: 32 });
    return bytesToHex(derivedKey);
  };

  const handleLogin = async () => {
    if (!nsec || !password) {
      setError('Both Secret Key and Password are required');
      return;
    }

    try {
      let privateKeyHex;
      let npub;
      
      if (nsec.startsWith('nsec')) {
        const { type, data } = nip19.decode(nsec);
        if (type !== 'nsec') throw new Error('Invalid nsec key');
        privateKeyHex = bytesToHex(data);
        npub = nip19.npubEncode(privateKeyHex);
      } else {
        if (!/^[0-9a-fA-F]{64}$/.test(nsec)) {
          throw new Error('Invalid hex format');
        }
        privateKeyHex = nsec.toLowerCase();
        npub = nip19.npubEncode(hexToBytes(privateKeyHex));
      }

      // Generate a random salt
      const salt = crypto.getRandomValues(new Uint8Array(16));
      
      // Derive encryption key from password
      const encryptionKey = deriveKey(password, salt);
      
      // Encrypt the private key
      const encryptedPrivateKey = await encrypt(privateKeyHex, encryptionKey);
      
      // Store encrypted private key, salt, and public key
      localStorage.setItem('privateKeyHexEncrypted', encryptedPrivateKey);
      localStorage.setItem('keySalt', bytesToHex(salt));
      localStorage.setItem('npub', npub);
      localStorage.setItem('aspUrl', 'https://insiders.signet.arklabs.to');
      
      const role = isCompany ? '/company' : '/freelance';
      navigate(role);
    } catch (err) {
      setError('Invalid key format or encryption error');
      console.error('Login error:', err);
    }
  };

  return (
    <div className="login-container">
      <h2>Virtual Bitcoin Inc.</h2>
      <div className="login-form">
        <div className="role-switch">
          <label>
            <input
              type="checkbox"
              checked={isCompany}
              onChange={(e) => setIsCompany(e.target.checked)}
            />
            Company Account
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="nsec">Secret Key</label>
          <input 
            type="text"
            id="nsec"
            placeholder="Enter nsec or hex private key" 
            value={nsec} 
            onChange={e => {
              setNsec(e.target.value);
              setError(''); 
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password (required)</label>
          <input 
            type="password"
            id="password"
            placeholder="Enter password" 
            value={password} 
            onChange={e => setPassword(e.target.value)} 
          />
        </div>
        {error && <div className="error-message">{error}</div>}
        <button onClick={handleLogin}>Login</button>
      </div>
    </div>
  );
}

export default Login;
