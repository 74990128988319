// Company.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { initSDK, init, receive, balance, unlock, lock, sendAsync, claim, getTransactionHistory } from './arkSDK';
import { QRCodeSVG } from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { nip19 } from 'nostr-tools';
import { hexToBytes } from '@noble/hashes/utils';
import { encrypt, decrypt, getPublicKeyFromPrivateKey } from './encryption';
import { pbkdf2 } from '@noble/hashes/pbkdf2';
import { sha256 } from '@noble/hashes/sha256';
import { bytesToHex } from '@noble/hashes/utils';
import { pubkyService } from './services/pubkyService';

// Add deriveKey function at the top level
const deriveKey = (password, salt) => {
  const passwordBytes = new TextEncoder().encode(password);
  const derivedKey = pbkdf2(sha256, passwordBytes, salt, { c: 100000, dkLen: 32 });
  return bytesToHex(derivedKey);
};

// Add these helper functions at the top
const truncateAddress = (address) => {
  if (!address) return '';
  if (address.length <= 12) return address;
  return `${address.slice(0, 6)}...${address.slice(-6)}`;
};

const satsToBTC = (sats) => {
  return (sats / 100000000).toFixed(8);
};

// Modal component for password prompts
const PasswordModal = ({ isOpen, onClose, onConfirm, title, isSend }) => {
  const [password, setPassword] = useState('');
  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [modalLogs, setModalLogs] = useState([]);

  if (!isOpen) return null;

  const handleConfirm = async () => {
    setIsProcessing(true);
    setModalLogs([]);
    try {
      if (isSend) {
        await onConfirm(password, address, parseInt(amount, 10), addModalLog);
      } else {
        await onConfirm(password, addModalLog);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const addModalLog = (message) => {
    setModalLogs(prev => [...prev, message]);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>{title}</h3>
        {isSend && (
          <>
            <div className="modal-input-group">
              <label>Virtual Bitcoin Address</label>
              <input
                type="text"
                placeholder="Enter recipient address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                disabled={isProcessing}
              />
            </div>
            <div className="modal-input-group">
              <label>Amount (sats)</label>
              <input
                type="number"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                disabled={isProcessing}
              />
            </div>
          </>
        )}
        <div className="modal-input-group">
          <label>Password</label>
          <input
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={isProcessing}
          />
        </div>
        {isProcessing && (
          <div className="modal-logs">
            <div className="modal-loader">🔄</div>
            {modalLogs.map((log, index) => (
              <div key={index} className="modal-log-line">{log}</div>
            ))}
          </div>
        )}
        <div className="modal-actions">
          {!isProcessing ? (
            <>
              <button onClick={handleConfirm}>Confirm</button>
              <button onClick={onClose}>Cancel</button>
            </>
          ) : (
            <button disabled>Processing...</button>
          )}
        </div>
      </div>
    </div>
  );
};

// Balance component
const BalanceDisplay = ({ balanceData, onRefresh, onSend, onClaim }) => (
  <div className="balance-display">
    <div className="balance-header">
      <h4>Virtual Bitcoin</h4>
      <button className="icon-button" onClick={onRefresh} title="Refresh Balance">
        🔄
      </button>
    </div>
    <p>{satsToBTC(balanceData.offchain)} BTC</p>
    <div className="balance-actions">
      <button onClick={onSend}>Send</button>
      <button onClick={onClaim}>Claim</button>
    </div>
    <div className="balance-item">
      <h4>Bitcoin</h4>
      <p>Spendable: {satsToBTC(balanceData.onchain.spendable)} BTC</p>
      <p>Locked: {satsToBTC(balanceData.onchain.locked)} BTC</p>
    </div>
  </div>
);

// Address display component
const AddressDisplay = ({ addresses }) => {
  const [copiedField, setCopiedField] = useState(null);
  
  if (!addresses) return null;

  const bip21String = `bitcoin:${addresses.boardingAddr}?ark=${addresses.offchainAddr}&amount=0.21`;

  const handleCopy = (field) => {
    setCopiedField(field);
    setTimeout(() => setCopiedField(null), 2000);
  };

  const CopyButton = ({ text, field }) => (
    <CopyToClipboard text={text} onCopy={() => handleCopy(field)}>
      <button className="copy-button">
        {copiedField === field ? '✓' : '📋'}
      </button>
    </CopyToClipboard>
  );

  return (
    <div className="address-display">
      <div className="qr-section">
        <QRCodeSVG value={bip21String} size={200} />
      </div>
      
      <div className="addresses-list">
        <div className="address-row">
          <div className="address-label">Bitcoin Invoice</div>
          <div className="address-value">
            <div className="address-text">{bip21String}</div>
            <CopyButton text={bip21String} field="bip21" />
          </div>
        </div>

        <div className="address-row">
          <div className="address-label">Bitcoin Address</div>
          <div className="address-value">
            <div className="address-text">{addresses.boardingAddr}</div>
            <CopyButton text={addresses.boardingAddr} field="boarding" />
          </div>
        </div>

        <div className="address-row">
          <div className="address-label">Virtual Bitcoin Address</div>
          <div className="address-value">
            <div className="address-text">{addresses.offchainAddr}</div>
            <CopyButton text={addresses.offchainAddr} field="offchain" />
          </div>
        </div>
      </div>
    </div>
  );
};

// Add this new component for collapsible sections
const CollapsibleSection = ({ title, children, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className="collapsible-section">
      <div 
        className="collapsible-header" 
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3>{title}</h3>
        <span className="toggle-icon">{isOpen ? '▼' : '▶'}</span>
      </div>
      {isOpen && (
        <div className="collapsible-content">
          {children}
        </div>
      )}
    </div>
  );
};

// Add this new component for payroll history
const PayrollHistory = ({ history, syncStatus, syncError }) => (
  <div className="payroll-history">
    <div className="payroll-header">
      <h3>Payroll History</h3>
      <div className={`sync-status status-${syncStatus}`}>
        {syncStatus === 'syncing' && <span className="sync-icon">🔄</span>}
        {syncStatus === 'synced' && <span className="sync-icon">✅</span>}
        {syncStatus === 'error' && (
          <span className="sync-icon" title={syncError}>❌</span>
        )}
        <span className="sync-text">
          {syncStatus === 'syncing' && 'Syncing...'}
          {syncStatus === 'synced' && 'Synced with Homeserver'}
          {syncStatus === 'error' && 'Sync Error'}
        </span>
      </div>
    </div>
    
    {/* Debug info */}
    <div className="debug-info" style={{ fontSize: '12px', color: '#666', margin: '10px 0' }}>
      Status: {syncStatus}, Records: {history?.length || 0}
    </div>

    {history && history.length > 0 ? (
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Total Amount</th>
            <th>Recipients</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {history.map((record, index) => (
            <tr key={index} className={`status-${record.status?.toLowerCase()}`}>
              <td>{new Date(record.timestamp).toLocaleString()}</td>
              <td>{record.type}</td>
              <td>{record.totalAmount ? satsToBTC(record.totalAmount) : '0'} BTC</td>
              <td>{record.recipientCount || '-'}</td>
              <td>{record.status || 'Unknown'}</td>
              <td>
                <button 
                  className="icon-button"
                  onClick={() => downloadTransactionCSV(record)}
                  title="Download CSV"
                >
                  📥
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <div className="no-records">
        {syncStatus === 'syncing' ? (
          'Loading payroll records...'
        ) : (
          'No payroll records found'
        )}
      </div>
    )}
  </div>
);

// Add these helper functions
const downloadTransactionCSV = async (record) => {
  try {
    let csvRows = [
      ['Koinly Date', 'Amount', 'Currency', 'Label', 'Description', 'TxHash'].join('\t')
    ];

    if (record.type === 'Payroll') {
      record.transactions.forEach(tx => {
        const btcAmount = (tx.amount / 100000000).toFixed(8);
        csvRows.push([
          new Date(tx.timestamp).toISOString().replace('T', ' ').replace(/\.\d{3}Z$/, ' UTC'),
          `-${btcAmount}`,
          'BTC',
          'cost',
          `Payment to ${tx.name} for services`,
          tx.txID || ''
        ].join('\t'));
      });
    }

    const blob = new Blob([csvRows.join('\n')], { type: 'text/tab-separated-values' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `payroll-transactions-${new Date(record.timestamp).toISOString()}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (err) {
    console.error("Error generating CSV:", err);
  }
};

// Update the getWalletHistory function
const getWalletHistory = async () => {
  try {
    const historyResponse = await getTransactionHistory();
    const history = typeof historyResponse === 'string' 
      ? JSON.parse(historyResponse)
      : historyResponse;

    console.log('Parsed history:', history);
    
    if (!Array.isArray(history)) {
      throw new Error('History is not in the expected format');
    }

    return history.map(tx => ({
      timestamp: new Date(tx.createdAt).getTime(),
      amount: parseInt(tx.amount, 10),
      type: tx.type === 'received' ? 'Deposit' : 'Withdrawal',
      txID: tx.type === 'received' ? tx.boardingTxid : tx.redeemTxid,
      label: tx.type === 'received' ? 'income' : 'cost',
      isPending: tx.isPending,
      createdAt: tx.createdAt
    }));
  } catch (err) {
    console.error("History error:", err);
    console.error("History error details:", {
      errorName: err.name,
      errorMessage: err.message,
      errorStack: err.stack
    });
    return [];
  }
};

// Add these constants at the top of the file
const STORAGE_KEY_PAYROLL = 'payrollHistory';
const STORAGE_KEY_PREFIX = 'payrollHistory_';

// Add this new component for transaction history
const TransactionHistory = ({ transactions }) => (
  <div className="transaction-history">
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Type</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Transaction ID</th>
        </tr>
      </thead>
      <tbody>
        {transactions.map((tx, index) => (
          <tr key={index} className={`type-${tx.type.toLowerCase()}`}>
            <td>{new Date(tx.createdAt).toLocaleString()}</td>
            <td>{tx.type === 'received' ? 'Deposit' : 'Withdrawal'}</td>
            <td className={tx.type === 'received' ? 'amount-positive' : 'amount-negative'}>
              {tx.type === 'received' ? '+' : '-'}{satsToBTC(tx.amount)} BTC
            </td>
            <td>{tx.isPending ? 'Pending' : 'Confirmed'}</td>
            <td className="tx-id">
              {truncateAddress(tx.type === 'received' ? tx.boardingTxid : tx.redeemTxid)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

// Add this new component for secure settings display
const SecureSettings = () => {
  const [password, setPassword] = useState('');
  const [showSecrets, setShowSecrets] = useState(false);
  const [privateKeyHex, setPrivateKeyHex] = useState(null);
  const [error, setError] = useState('');

  // Add deriveKey function
  const deriveKey = (password, salt) => {
    const passwordBytes = new TextEncoder().encode(password);
    const derivedKey = pbkdf2(sha256, passwordBytes, salt, { c: 100000, dkLen: 32 });
    return bytesToHex(derivedKey);
  };

  const handleUnlock = async () => {
    try {
      const encryptedPrivateKey = localStorage.getItem('privateKeyHexEncrypted');
      const saltHex = localStorage.getItem('keySalt');
      
      if (!encryptedPrivateKey || !saltHex) {
        throw new Error('No encrypted private key found');
      }

      const salt = hexToBytes(saltHex);
      const encryptionKey = deriveKey(password, salt);
      const decryptedPrivateKey = await decrypt(encryptedPrivateKey, encryptionKey);
      
      // Convert hex to nsec for display
      const nsec = nip19.nsecEncode(hexToBytes(decryptedPrivateKey));
      setPrivateKeyHex(nsec);
      setShowSecrets(true);
      setError('');
    } catch (err) {
      setError('Invalid password');
      console.error('Unlock error:', err);
    }
  };

  const handleLock = () => {
    setPrivateKeyHex(null);
    setShowSecrets(false);
    setPassword('');
  };

  return (
    <div className="secure-settings">
      <div className="settings-form">
        <div className="form-group">
          <label>ASP URL</label>
          <input
            type="text"
            value={localStorage.getItem('aspUrl') || ''}
            readOnly
          />
        </div>
        
        <div className="form-group">
          <label>Public Key (npub)</label>
          <input
            type="text"
            value={localStorage.getItem('npub') || ''}
            readOnly
          />
        </div>

        {!showSecrets ? (
          <div className="unlock-section">
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password to view secret key"
              />
            </div>
            {error && <div className="error-message">{error}</div>}
            <button onClick={handleUnlock}>Unlock</button>
          </div>
        ) : (
          <div className="secrets-section">
            <div className="form-group">
              <label>Secret Key (nsec)</label>
              <div className="secret-input-group">
                <input
                  type="text"
                  value={privateKeyHex || ''}
                  readOnly
                />
                <CopyToClipboard text={privateKeyHex || ''}>
                  <button className="icon-button" title="Copy to clipboard">
                    📋
                  </button>
                </CopyToClipboard>
              </div>
            </div>
            <button onClick={handleLock}>Lock</button>
          </div>
        )}
      </div>
    </div>
  );
};

// Add this new component for wallet unlock
const UnlockModal = ({ isOpen, onUnlock }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  if (!isOpen) return null;

  const handleUnlock = async () => {
    if (!password) {
      setError('Password is required');
      return;
    }

    setIsProcessing(true);
    setError('');

    try {
      // Get the encrypted private key and salt
      const encryptedPrivateKey = localStorage.getItem('privateKeyHexEncrypted');
      const saltHex = localStorage.getItem('keySalt');
      
      if (!encryptedPrivateKey || !saltHex) {
        throw new Error('No encrypted private key or salt found');
      }

      // Derive key and decrypt private key
      const salt = hexToBytes(saltHex);
      const encryptionKey = deriveKey(password, salt);
      const privateKey = await decrypt(encryptedPrivateKey, encryptionKey);

      // Call the onUnlock callback with the decrypted private key and password
      await onUnlock(privateKey, password);
      setPassword('');
    } catch (err) {
      console.error('Unlock error:', err);
      setError('Invalid password');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Unlock Wallet</h3>
        <div className="modal-input-group">
          <label>Password</label>
          <input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setError('');
            }}
            disabled={isProcessing}
          />
        </div>
        {error && <div className="error-message">{error}</div>}
        <div className="modal-actions">
          {!isProcessing ? (
            <button onClick={handleUnlock}>Unlock</button>
          ) : (
            <button disabled>Unlocking...</button>
          )}
        </div>
      </div>
    </div>
  );
};

// Add this new component for homeserver settings
const HomeserverSettings = () => {
  const [currentHomeserver, setCurrentHomeserver] = useState(null);
  const [pubkyStatus, setPubkyStatus] = useState('disconnected'); // 'connected', 'disconnected', 'error'
  const [pubkyError, setPubkyError] = useState(null);
  const [showAdvanced, setShowAdvanced] = useState(false);

  useEffect(() => {
    const checkConnection = async () => {
      try {
        if (pubkyService.isInitialized) {
          const publicKey = pubkyService.keypair.publicKey();
          const session = await pubkyService.client.session(publicKey);
          if (session) {
            setPubkyStatus('connected');
            setCurrentHomeserver({
              pubkey: pubkyService.homeserver.z32(),
              capabilities: session.capabilities()
            });
          } else {
            setPubkyStatus('disconnected');
          }
        } else {
          setPubkyStatus('disconnected');
        }
      } catch (err) {
        console.error('Error checking Pubky connection:', err);
        setPubkyStatus('error');
        setPubkyError(err.message);
      }
    };

    checkConnection();
  }, []);

  const handleReconnect = async () => {
    try {
      setPubkyStatus('connecting');
      setPubkyError(null);

      const privateKeyHex = localStorage.getItem('privateKeyHexEncrypted');
      const saltHex = localStorage.getItem('keySalt');
      const password = prompt('Enter your password to reconnect:');
      
      if (!password || !privateKeyHex || !saltHex) {
        throw new Error('Missing credentials');
      }

      const salt = hexToBytes(saltHex);
      const encryptionKey = deriveKey(password, salt);
      const decryptedPrivateKey = await decrypt(privateKeyHex, encryptionKey);

      await pubkyService.initialize(decryptedPrivateKey);
      
      const publicKey = pubkyService.keypair.publicKey();
      const session = await pubkyService.client.session(publicKey);
      
      if (session) {
        setPubkyStatus('connected');
        setCurrentHomeserver({
          pubkey: pubkyService.homeserver.z32(),
          capabilities: session.capabilities()
        });
      } else {
        throw new Error('Failed to establish session');
      }
    } catch (err) {
      console.error('Reconnection error:', err);
      setPubkyStatus('error');
      setPubkyError(err.message);
    }
  };

  return (
    <div className="homeserver-settings">
      <div className="connection-status">
        <h4>Homeserver Connection</h4>
        <div className={`status-indicator ${pubkyStatus}`}>
          {pubkyStatus === 'connected' && '🟢 Connected'}
          {pubkyStatus === 'disconnected' && '⚪ Disconnected'}
          {pubkyStatus === 'connecting' && '🟡 Connecting...'}
          {pubkyStatus === 'error' && '🔴 Error'}
        </div>
        {pubkyError && (
          <div className="error-message">
            {pubkyError}
          </div>
        )}
      </div>

      {currentHomeserver && (
        <div className="homeserver-info">
          <div className="info-row">
            <label>Homeserver Public Key:</label>
            <div className="pubkey-display">
              <code>{currentHomeserver.pubkey}</code>
              <CopyToClipboard text={currentHomeserver.pubkey}>
                <button className="icon-button" title="Copy">📋</button>
              </CopyToClipboard>
            </div>
          </div>

          <div className="info-row">
            <label>Capabilities:</label>
            <ul className="capabilities-list">
              {currentHomeserver.capabilities.map((cap, index) => (
                <li key={index}>{cap}</li>
              ))}
            </ul>
          </div>

          <button 
            className={`reconnect-button ${pubkyStatus}`}
            onClick={handleReconnect}
            disabled={pubkyStatus === 'connecting'}
          >
            {pubkyStatus === 'connecting' ? 'Connecting...' : 'Reconnect'}
          </button>
        </div>
      )}

      <div className="advanced-settings">
        <button 
          className="toggle-advanced"
          onClick={() => setShowAdvanced(!showAdvanced)}
        >
          {showAdvanced ? 'Hide Advanced Settings' : 'Show Advanced Settings'} ▼
        </button>
        
        {showAdvanced && (
          <div className="advanced-content">
            <div className="warning">
              ⚠️ Changing these settings may cause data loss. Only proceed if you know what you're doing.
            </div>
            <button 
              className="danger-button"
              onClick={() => {
                if (window.confirm('Are you sure? This will clear all local data and require re-initialization.')) {
                  pubkyService.cleanup()
                    .then(() => {
                      setPubkyStatus('disconnected');
                      setCurrentHomeserver(null);
                    })
                    .catch(console.error);
                }
              }}
            >
              Reset Connection
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

// Add some CSS for the new component
const styles = `
  .homeserver-settings {
    padding: 20px;
    background: #f5f5f5;
    border-radius: 8px;
  }

  .connection-status {
    margin-bottom: 20px;
  }

  .status-indicator {
    padding: 8px;
    border-radius: 4px;
    margin: 10px 0;
    font-weight: bold;
  }

  .status-indicator.connected { background: #e6ffe6; }
  .status-indicator.disconnected { background: #f5f5f5; }
  .status-indicator.connecting { background: #fff3e6; }
  .status-indicator.error { background: #ffe6e6; }

  .homeserver-info {
    background: white;
    padding: 15px;
    border-radius: 4px;
    margin: 10px 0;
  }

  .info-row {
    margin: 10px 0;
  }

  .info-row label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }

  .pubkey-display {
    display: flex;
    align-items: center;
    gap: 10px;
    background: #f5f5f5;
    padding: 8px;
    border-radius: 4px;
  }

  .capabilities-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .capabilities-list li {
    background: #f5f5f5;
    padding: 4px 8px;
    margin: 4px 0;
    border-radius: 4px;
    font-family: monospace;
  }

  .reconnect-button {
    margin-top: 15px;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    background: #007bff;
    color: white;
  }

  .reconnect-button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }

  .advanced-settings {
    margin-top: 20px;
    border-top: 1px solid #ddd;
    padding-top: 20px;
  }

  .toggle-advanced {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 8px;
    width: 100%;
    text-align: left;
  }

  .advanced-content {
    margin-top: 15px;
  }

  .warning {
    background: #fff3e6;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
  }

  .danger-button {
    background: #dc3545;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }

  .danger-button:hover {
    background: #c82333;
  }

  .error-message {
    color: #dc3545;
    margin-top: 5px;
    font-size: 0.9em;
  }
`;

function Company() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('wallet');
  const [balanceData, setBalanceData] = useState({ offchain: 0, onchain: { spendable: 0, locked: 0 } });
  const [addresses, setAddresses] = useState(null);
  const [isSDKReady, setIsSDKReady] = useState(false);
  const [sdkError, setSDKError] = useState(null);
  const [isWalletInitialized, setIsWalletInitialized] = useState(false);
  const [modalConfig, setModalConfig] = useState({ isOpen: false, type: null });
  const [logs, setLogs] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formData, setFormData] = useState({
    aspUrl: localStorage.getItem('aspUrl') || 'https://insiders.signet.arklabs.to',
    password: localStorage.getItem('password') || '',
    privateKey: localStorage.getItem('nsec') || '',
    sendAddress: '',
    amount: '',
  });
  const [payrollPassword, setPayrollPassword] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [showSecrets, setShowSecrets] = useState(false);
  const [payrollHistory, setPayrollHistory] = useState([]);
  const [walletHistory, setWalletHistory] = useState([]);
  const [btcEurPrice, setBtcEurPrice] = useState(null);
  const [privateKeyHex, setPrivateKeyHex] = useState(null);
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [showUnlockModal, setShowUnlockModal] = useState(true);
  const [syncStatus, setSyncStatus] = useState('synced');
  const [syncError, setSyncError] = useState(null);

  // Add effect to load encrypted payroll history
  useEffect(() => {
    const loadHistory = async () => {
      if (formData.privateKey) {
        const history = await loadEncryptedPayrollHistory(formData.privateKey);
        setPayrollHistory(history);
      }
    };
    loadHistory();
  }, [formData.privateKey]);

  // Update effect to save encrypted payroll history
  useEffect(() => {
    if (formData.privateKey && payrollHistory.length > 0) {
      saveEncryptedPayrollHistory(payrollHistory, formData.privateKey);
    }
  }, [payrollHistory, formData.privateKey]);

  // Update the initialization useEffect
  useEffect(() => {
    const loadSDK = async () => {
      try {
        setSDKError(null);
        const success = await initSDK(); // Don't pass privateKey here
        if (success) {
          logMessage("SDK initialized successfully");
          setIsSDKReady(true);
          setShowUnlockModal(true); // Show unlock modal after SDK is ready
        }
      } catch (err) {
        const errorMessage = err.message || 'Unknown error initializing SDK';
        setSDKError(errorMessage);
        logMessage("SDK initialization error: " + errorMessage);
        console.error('SDK Error:', err);
      }
    };

    loadSDK();
  }, []);

  // Update the handleUnlock function
  const handleUnlock = async (privateKey, password) => {
    try {
      if (!isSDKReady) {
        throw new Error('SDK not ready. Please wait and try again.');
      }

      // Initialize Pubky with the private key
      try {
        await pubkyService.initialize(privateKey);
        logMessage("Pubky storage initialized successfully");
      } catch (pubkyErr) {
        logMessage("Warning: Pubky storage initialization failed: " + pubkyErr.message);
        // Continue with wallet initialization even if Pubky fails
      }

      const chain = "bitcoin";
      const walletType = "singlekey";
      const clientType = "rest";
      const aspUrl = localStorage.getItem('aspUrl');

      console.log('Initializing wallet...');

      await init(
        walletType,
        clientType,
        aspUrl,
        privateKey,
        password,
        chain
      );

      console.log('Wallet initialized successfully');
      setIsUnlocked(true);
      setShowUnlockModal(false);
      setIsWalletInitialized(true);
      logMessage("Wallet unlocked and initialized");

      // Fetch initial data
      try {
        console.log('Fetching addresses...');
        const addrs = await receive();
        setAddresses(addrs);
        
        console.log('Fetching balance...');
        const bal = await balance(false);
        setBalanceData({
          offchain: bal.offchainBalance,
          onchain: {
            spendable: bal.onchainBalance.spendable,
            locked: bal.onchainBalance.locked
          }
        });
      } catch (dataErr) {
        console.error('Error fetching initial data:', dataErr);
        logMessage("Error fetching initial data: " + dataErr.message);
      }
    } catch (err) {
      console.error('Initialization error:', err);
      logMessage("Initialization error: " + err.message);
      throw err;
    }
  };

  // Log messages similar to the HTML example
  const logMessage = (message) => {
    setLogs(prev => [...prev, message]);
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [id]: value
    }));
  };

  // Get receive addresses
  const receiveAddresses = async () => {
    try {
      const addresses = await receive();
      logMessage("Offchain address: " + addresses.offchainAddr);
      logMessage("Boarding address: " + addresses.boardingAddr);
    } catch (err) {
      logMessage("Receive error: " + err.message);
    }
  };

  // Get balance
  const getBalance = async () => {
    try {
      const bal = await balance(false);
      logMessage("Offchain balance: " + satsToBTC(bal.offchainBalance) + " BTC");
      logMessage("Onchain balance: ");
      logMessage("  Spendable: " + satsToBTC(bal.onchainBalance.spendable) + " BTC");
      logMessage("  Locked: " + satsToBTC(bal.onchainBalance.locked) + " BTC");
      setBalanceData({
        offchain: bal.offchainBalance,
        onchain: {
          spendable: bal.onchainBalance.spendable,
          locked: bal.onchainBalance.locked
        }
      });
    } catch (err) {
      logMessage("Balance error: " + err.message);
    }
  };

  // Send funds
  const handleSend = async (password, address, amount) => {
    try {
      const decryptedKey = await decryptPrivateKey(password);
      await unlock(password);
      const txID = await sendAsync(false, [{ To: address, Amount: amount }]);
      logMessage(`Sent ${satsToBTC(amount)} BTC with tx ID: ${txID}`);
      setPrivateKeyHex(decryptedKey); // Store decrypted key for other operations
    } catch (err) {
      logMessage("Send error: " + err.message);
    } finally {
      await lock(password);
      setModalConfig({ isOpen: false, type: null });
    }
  };

  // Claim VTXOs
  const handleClaim = async (password, addLog) => {
    try {
      const decryptedKey = await decryptPrivateKey(password);
      addLog("Unlocking wallet...");
      await unlock(password);
      
      addLog("Starting claim process...");
      const txID = await claim();
      addLog("Claim successful!");
      addLog(`Transaction ID: ${txID}`);
      logMessage("Claimed money with tx ID: " + txID);
      
      setPrivateKeyHex(decryptedKey); // Store decrypted key for other operations
      await refreshBalance();
    } catch (err) {
      addLog("Error: " + err.message);
      logMessage("Claim error: " + err.message);
    } finally {
      addLog("Locking wallet...");
      await lock(password);
      setModalConfig({ isOpen: false, type: null });
    }
  };

  // CSV handling
  const handleUploadCSV = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    
    reader.onload = (event) => {
      const text = event.target.result;
      const rows = text.split('\n')
        .filter(row => row.trim())
        .slice(1);  // Skip header row
      
      const parsedData = rows.map(row => {
        const [name, address, btcAmount] = row.split(',').map(item => item.trim());
        
        // Convert BTC amount to sats (1 BTC = 100,000,000 sats)
        const satsAmount = Math.round(parseFloat(btcAmount) * 100000000);
        
        // Extract Virtual Bitcoin address from BIP21 URI if present
        let virtualBitcoinAddress = address;
        if (address.startsWith('bitcoin:')) {
          const url = new URL(address);
          virtualBitcoinAddress = url.searchParams.get('ark');
        }

        return { 
          name, 
          address: virtualBitcoinAddress,
          originalAddress: address,
          amount: satsAmount 
        };
      });
      setCsvData(parsedData);
      setShowConfirmation(true);
    };
    
    reader.readAsText(file);
  };

  // Bulk payment processing
  const processBulkPayments = async () => {
    if (!payrollPassword) {
      logMessage("Error: password is required");
      return;
    }

    setIsProcessing(true);
    setSyncStatus('syncing');
    setSyncError(null);
    const timestamp = Date.now();

    try {
      const decryptedKey = await decryptPrivateKey(payrollPassword);
      await unlock(payrollPassword);
      logMessage("Starting bulk payments...");

      const transactions = [];
      const withdrawalTxIds = [];
      let hasPendingTransactions = false;

      for (const payment of csvData) {
        try {
          const txID = await sendAsync(false, [{ 
            To: payment.address, 
            Amount: payment.amount 
          }]);
          withdrawalTxIds.push(txID);
          
          // Check if transaction is confirmed
          const txStatus = await checkTransactionStatus(txID);
          const isPending = txStatus === 'pending';
          hasPendingTransactions = hasPendingTransactions || isPending;

          transactions.push({
            timestamp: Date.now(),
            name: payment.name,
            address: payment.address,
            amount: payment.amount,
            status: isPending ? 'pending' : 'success',
            txID: txID,
            type: 'Withdrawal'
          });
          logMessage(`Paid ${payment.name}: ${satsToBTC(payment.amount)} BTC (TX: ${txID}) - ${isPending ? 'Pending' : 'Confirmed'}`);
        } catch (err) {
          transactions.push({
            timestamp: Date.now(),
            name: payment.name,
            address: payment.address,
            amount: payment.amount,
            status: 'failed',
            error: err.message,
            type: 'Withdrawal'
          });
          logMessage(`Failed to pay ${payment.name}: ${err.message}`);
        }
      }

      const newPayrollRecord = {
        timestamp,
        type: 'Payroll',
        totalAmount: transactions.reduce((sum, tx) => sum + tx.amount, 0),
        recipientCount: transactions.length,
        status: hasPendingTransactions ? 'Pending' : 
                transactions.every(tx => tx.status === 'success') ? 'Completed' : 'Partial',
        transactions: transactions,
        withdrawalTxIds
      };

      // Only store in Pubky if there are no pending transactions
      if (!hasPendingTransactions) {
        try {
          const pubkyUrl = await pubkyService.storePayrollFile(newPayrollRecord);
          newPayrollRecord.pubkyUrl = pubkyUrl;
          setSyncStatus('synced');
          logMessage("Payroll record stored in Pubky successfully");
          
          // Update state with latest data from Pubky
          await loadPayrollHistoryFromPubky();
        } catch (pubkyErr) {
          console.error('Failed to store in Pubky:', pubkyErr);
          setSyncStatus('error');
          setSyncError(pubkyErr.message);
          logMessage("Error: Failed to store payroll record: " + pubkyErr.message);
        }
      } else {
        // Store temporarily in state and set up monitoring
        setPayrollHistory(prev => [...prev, newPayrollRecord]);
        logMessage("Payroll has pending transactions - will store when confirmed");
        monitorPendingPayroll(newPayrollRecord);
      }

      setCsvData(transactions);
      await refreshBalance();
    } catch (err) {
      setSyncStatus('error');
      setSyncError(err.message);
      logMessage("Bulk payment error: " + err.message);
    } finally {
      await lock(payrollPassword);
      setIsProcessing(false);
      setPayrollPassword('');
    }
  };

  // Add function to monitor pending transactions
  const monitorPendingPayroll = (payrollRecord) => {
    const checkInterval = setInterval(async () => {
      try {
        const updatedTransactions = await Promise.all(
          payrollRecord.transactions.map(async tx => {
            if (tx.status === 'pending') {
              const status = await checkTransactionStatus(tx.txID);
              return {
                ...tx,
                status: status === 'pending' ? 'pending' : 'success'
              };
            }
            return tx;
          })
        );

        const hasPendingTransactions = updatedTransactions.some(tx => tx.status === 'pending');
        
        if (!hasPendingTransactions) {
          // All transactions are confirmed, store in Pubky
          const finalRecord = {
            ...payrollRecord,
            transactions: updatedTransactions,
            status: updatedTransactions.every(tx => tx.status === 'success') ? 'Completed' : 'Partial'
          };

          try {
            const pubkyUrl = await pubkyService.storePayrollFile(finalRecord);
            finalRecord.pubkyUrl = pubkyUrl;
            setSyncStatus('synced');
            logMessage("Pending payroll confirmed and stored in Pubky successfully");
            
            // Update state with latest data
            await loadPayrollHistoryFromPubky();
          } catch (err) {
            console.error('Failed to store confirmed payroll:', err);
            setSyncStatus('error');
            setSyncError(err.message);
          }

          clearInterval(checkInterval);
        }
      } catch (err) {
        console.error('Error monitoring pending transactions:', err);
      }
    }, 30000); // Check every 30 seconds

    // Clean up interval after 1 hour if still pending
    setTimeout(() => {
      clearInterval(checkInterval);
    }, 3600000);
  };

  // Add helper function to check transaction status
  const checkTransactionStatus = async (txId) => {
    try {
      // Implement your transaction status check here
      // This will depend on your blockchain API
      const status = await getTransactionStatus(txId);
      return status;
    } catch (err) {
      console.error('Error checking transaction status:', err);
      return 'pending'; // Assume pending on error
    }
  };

  const fetchBTCEURPrice = async () => {
    try {
      const response = await fetch('https://blockchain.info/ticker');
      const data = await response.json();
      setBtcEurPrice(data.EUR.last);
    } catch (err) {
      console.error("Error fetching BTC/EUR price:", err);
      logMessage("Error fetching BTC/EUR price: " + err.message);
    }
  };

  useEffect(() => {
    if (showConfirmation) {
      fetchBTCEURPrice();
    }
  }, [showConfirmation]);

  const PayrollConfirmation = () => {
    // Move password state inside component to maintain focus
    const [localPassword, setLocalPassword] = useState(payrollPassword);

    // Use useEffect to sync with parent state
    useEffect(() => {
      setPayrollPassword(localPassword);
    }, [localPassword]);

    return (
      <div className="payroll-confirmation">
        <h3>Confirm Payments</h3>
        <div className="price-info">
          {btcEurPrice && (
            <p>Current BTC/EUR Rate: €{btcEurPrice.toLocaleString()}</p>
          )}
        </div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>Amount (BTC)</th>
              <th>Amount (EUR)</th>
              <th>Status</th>
              {isProcessing && <th>Transaction ID</th>}
            </tr>
          </thead>
          <tbody>
            {csvData.map((row, index) => {
              const btcAmount = row.amount / 100000000;
              const eurAmount = btcEurPrice ? btcAmount * btcEurPrice : null;
              
              return (
                <tr key={index} className={`status-${row.status || 'pending'}`}>
                  <td>{row.name}</td>
                  <td>{truncateAddress(row.address)}</td>
                  <td>{btcAmount.toFixed(8)}</td>
                  <td>
                    {eurAmount ? 
                      `€${eurAmount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}` : 
                      '...'
                    }
                  </td>
                  <td>{row.status || 'pending'}</td>
                  {isProcessing && <td>{row.txID ? truncateAddress(row.txID) : row.error || '-'}</td>}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="total">
          <div>
            Total Amount: {(csvData.reduce((sum, row) => sum + row.amount, 0) / 100000000).toFixed(8)} BTC
          </div>
          {btcEurPrice && (
            <div>
              Total EUR: €{(csvData.reduce((sum, row) => sum + row.amount, 0) / 100000000 * btcEurPrice).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </div>
          )}
        </div>
        <div className="confirmation-actions">
          {!isProcessing ? (
            <>
              <input
                type="password"
                placeholder="Wallet Password"
                value={localPassword}
                onChange={(e) => setLocalPassword(e.target.value)}
              />
              <button 
                onClick={processBulkPayments}
                disabled={!localPassword}
              >
                Process Payments
              </button>
              <button onClick={() => {
                setShowConfirmation(false);
                setCsvData([]);
                setLocalPassword('');
              }}>
                Cancel
              </button>
            </>
          ) : (
            <button disabled>Processing...</button>
          )}
        </div>
      </div>
    );
  };

  const refreshAddresses = async () => {
    try {
      const addrs = await receive();
      setAddresses(addrs);
    } catch (err) {
      console.error("Receive error: " + err.message);
    }
  };

  const refreshBalance = async () => {
    try {
      const bal = await balance(false);
      setBalanceData({
        offchain: bal.offchainBalance,
        onchain: {
          spendable: bal.onchainBalance.spendable,
          locked: bal.onchainBalance.locked
        }
      });
      await fetchTransactionHistory(); // Fetch updated transaction history
    } catch (err) {
      console.error("Balance error: " + err.message);
    }
  };

  // Add CSV template functions
  const csvTemplate = "Name,Address,Amount\nMario,bitcoin:tb1pyntmnxnpqap0metanjms0rf5y56ngd6t4n4sl6zs8e8xaq5kue2q89nmcq?ark=tark1qd90wnfly2zd5749lse0mgyttyzaumy35wx8rnvug3sz30wkl805quayedvwf26ug08qkk3fjxzafapltxvkdjzt4wfdcs2mgwycm4qvr7pye,0.00005\nPino,bitcoin:tb1pyntmnxnpqap0metanjms0rf5y56ngd6t4n4sl6zs8e8xaq5kue2q89nmcq?ark=tark1qd90wnfly2zd5749lse0mgyttyzaumy35wx8rnvug3sz30wkl805quayedvwf26ug08qkk3fjxzafapltxvkdjzt4wfdcs2mgwycm4qvr7pye,0.00002\nLuigi,bitcoin:tb1pyntmnxnpqap0metanjms0rf5y56ngd6t4n4sl6zs8e8xaq5kue2q89nmcq?ark=tark1qd90wnfly2zd5749lse0mgyttyzaumy35wx8rnvug3sz30wkl805quayedvwf26ug08qkk3fjxzafapltxvkdjzt4wfdcs2mgwycm4qvr7pye,0.00003";
  
  const downloadTemplate = () => {
    const element = document.createElement('a');
    const file = new Blob([csvTemplate], {type: 'text/csv'});
    element.href = URL.createObjectURL(file);
    element.download = 'payroll-template.csv';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const downloadLogs = () => {
    const logText = logs.join('\n');
    const blob = new Blob([logText], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const element = document.createElement('a');
    element.href = url;
    element.download = `virtual-bitcoin-logs-${new Date().toISOString()}.txt`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    URL.revokeObjectURL(url);
  };

  // Update the exportAllTransactions function
  const exportAllTransactions = async () => {
    try {
      const walletHistory = await getWalletHistory();
      
      let csvRows = [
        ['Koinly Date', 'Amount', 'Currency', 'Label', 'Description', 'TxHash'].join('\t')
      ];

      // Add wallet transactions with BTC amounts
      walletHistory.forEach(tx => {
        if (!tx.isPending) {
          const btcAmount = (tx.amount / 100000000).toFixed(8);
          csvRows.push([
            tx.createdAt,
            tx.type === 'Deposit' ? btcAmount : `-${btcAmount}`,
            'BTC',
            tx.type === 'Deposit' ? 'income' : 'cost',
            tx.type === 'Deposit' ? 'deposit' : 'withdrawal',
            tx.txID || ''
          ].join('\t'));
        }
      });

      // Add payroll transactions with withdrawal tag and recipient name
      if (Array.isArray(payrollHistory)) {
        payrollHistory.forEach(record => {
          if (record.type === 'Payroll' && Array.isArray(record.transactions)) {
            record.transactions.forEach(tx => {
              if (tx.status === 'success') {
                const btcAmount = (tx.amount / 100000000).toFixed(8);
                csvRows.push([
                  new Date(tx.timestamp).toISOString().replace('T', ' ').replace(/\.\d{3}Z$/, ' UTC'),
                  `-${btcAmount}`,
                  'BTC',
                  'cost',
                  `withdrawal, payment to ${tx.name}`,
                  tx.txID || ''
                ].join('\t'));
              }
            });
          }
        });
      }

      // Sort all transactions by date
      csvRows = [
        csvRows[0],
        ...csvRows.slice(1).sort((a, b) => {
          const dateA = new Date(a.split('\t')[0]);
          const dateB = new Date(b.split('\t')[0]);
          return dateB - dateA;
        })
      ];

      const blob = new Blob([csvRows.join('\n')], { type: 'text/tab-separated-values' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `virtual-bitcoin-transactions-${new Date().toISOString()}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (err) {
      console.error("Error exporting transactions:", err);
      logMessage("Error exporting transactions: " + err.message);
    }
  };

  // Add function to fetch transaction history
  const fetchTransactionHistory = async () => {
    try {
      const historyResponse = await getTransactionHistory();
      const history = typeof historyResponse === 'string' 
        ? JSON.parse(historyResponse)
        : historyResponse;

      if (Array.isArray(history)) {
        setWalletHistory(history);
      }
    } catch (err) {
      console.error("Error fetching transaction history:", err);
      logMessage("Error fetching transaction history: " + err.message);
    }
  };

  // Add effect to fetch transaction history on mount and wallet initialization
  useEffect(() => {
    if (isWalletInitialized) {
      fetchTransactionHistory();
    }
  }, [isWalletInitialized]);

  // Add function to decrypt private key
  const decryptPrivateKey = async (password) => {
    try {
      const encryptedPrivateKey = localStorage.getItem('privateKeyHexEncrypted');
      const saltHex = localStorage.getItem('keySalt');
      
      if (!encryptedPrivateKey || !saltHex) {
        throw new Error('No encrypted private key or salt found');
      }

      const salt = hexToBytes(saltHex);
      const encryptionKey = deriveKey(password, salt);
      
      const decryptedPrivateKey = await decrypt(encryptedPrivateKey, encryptionKey);
      return decryptedPrivateKey;
    } catch (err) {
      console.error('Error decrypting private key:', err);
      throw err;
    }
  };

  // Add function to load payroll history from Pubky
  const loadPayrollHistoryFromPubky = async () => {
    setSyncStatus('syncing');
    setSyncError(null);

    try {
      logMessage("Loading payroll history from Pubky...");
      const files = await pubkyService.listPayrollFiles();
      console.log('Found payroll files:', files); // Debug log
      
      if (files.length === 0) {
        logMessage("No payroll history found in Pubky");
        setPayrollHistory([]);
        setSyncStatus('synced');
        return;
      }

      const history = await Promise.all(
        files.map(async (url) => {
          try {
            console.log('Fetching record from:', url); // Debug log
            const record = await pubkyService.getPayrollFile(url);
            console.log('Fetched record:', record); // Debug log
            if (record) {
              record.pubkyUrl = url;
              return record;
            }
            console.warn(`Failed to load payroll record: ${url}`);
            return null;
          } catch (err) {
            console.error(`Error loading payroll record ${url}:`, err);
            return null;
          }
        })
      );
      
      const validHistory = history
        .filter(record => record !== null)
        .sort((a, b) => b.timestamp - a.timestamp);
      
      console.log('Setting payroll history:', validHistory); // Debug log
      setPayrollHistory(validHistory);
      setSyncStatus('synced');
      logMessage(`Loaded ${validHistory.length} payroll records from Pubky`);
    } catch (err) {
      console.error('Error loading payroll history from Pubky:', err);
      setSyncStatus('error');
      setSyncError(err.message);
      logMessage('Error loading payroll history: ' + err.message);
      setPayrollHistory([]);
    }
  };

  // Add effect to load payroll history when wallet is unlocked
  useEffect(() => {
    if (isUnlocked) {
      loadPayrollHistoryFromPubky();
    }
  }, [isUnlocked]);

  // Add cleanup on component unmount
  useEffect(() => {
    return () => {
      pubkyService.cleanup().catch(console.error);
    };
  }, []);

  return (
    <div className="company-dashboard">
      <UnlockModal 
        isOpen={showUnlockModal && isSDKReady}
        onUnlock={handleUnlock}
      />

      <h2>Virtual Bitcoin Company Dashboard</h2>
      
      <div className="tabs">
        <button 
          className={activeTab === 'wallet' ? 'active' : ''} 
          onClick={() => setActiveTab('wallet')}
        >
          Wallet
        </button>
        <button 
          className={activeTab === 'payroll' ? 'active' : ''} 
          onClick={() => setActiveTab('payroll')}
        >
          Payroll
        </button>
        <button 
          className={activeTab === 'settings' ? 'active' : ''} 
          onClick={() => setActiveTab('settings')}
        >
          Settings
        </button>
      </div>

      <div className="tab-content">
        {activeTab === 'wallet' && (
          <div className="wallet-tab">
            <CollapsibleSection title="Deposit Bitcoin" defaultOpen={true}>
              <AddressDisplay addresses={addresses} />
            </CollapsibleSection>

            <CollapsibleSection title="Virtual Bitcoin Account" defaultOpen={true}>
              <BalanceDisplay 
                balanceData={balanceData}
                onRefresh={refreshBalance}
                onSend={() => setModalConfig({ isOpen: true, type: 'send' })}
                onClaim={() => setModalConfig({ isOpen: true, type: 'claim' })}
              />
            </CollapsibleSection>

            <CollapsibleSection title="Transaction History">
              <TransactionHistory 
                transactions={walletHistory} 
              />
            </CollapsibleSection>
          </div>
        )}

        {activeTab === 'payroll' && (
          <div className="payroll-tab">
            <h2>Payroll Management</h2>
            
            <CollapsibleSection title="New Payroll" defaultOpen={true}>
              {!showConfirmation ? (
                <div className="payroll-controls">
                  <input 
                    type="file" 
                    accept=".csv"
                    onChange={handleUploadCSV}
                    disabled={isProcessing}
                  />
                  <button 
                    onClick={() => setActiveTab('settings')} 
                    title="View CSV Format Instructions"
                    className="help-button"
                  >
                    CSV Format Help
                  </button>
                </div>
              ) : (
                <PayrollConfirmation />
              )}
            </CollapsibleSection>

            <CollapsibleSection title="Payroll History">
              <PayrollHistory history={payrollHistory} syncStatus={syncStatus} syncError={syncError} />
            </CollapsibleSection>
          </div>
        )}

        {activeTab === 'settings' && (
          <div className="settings-tab">
            <CollapsibleSection title="Connection Settings" defaultOpen={true}>
              <SecureSettings />
            </CollapsibleSection>

            <CollapsibleSection title="Homeserver Connection" defaultOpen={true}>
              <HomeserverSettings />
            </CollapsibleSection>

            <CollapsibleSection title="CSV Format Instructions">
              <div className="csv-instructions">
                <h3>CSV Format Instructions</h3>
                <p>The CSV file should contain the following columns:</p>
                <ul>
                  <li><strong>Name:</strong> Employee/Freelancer name</li>
                  <li><strong>Address:</strong> Either:
                    <ul>
                      <li>Virtual Bitcoin address (ark1...)</li>
                      <li>Bitcoin Invoice (bitcoin:...?ark=...)</li>
                    </ul>
                  </li>
                  <li><strong>Amount:</strong> Payment amount in BTC (e.g., 0.00002 BTC = 2,000 sats)</li>
                </ul>
                <div className="csv-template">
                  <button onClick={downloadTemplate}>Download Template</button>
                  <div className="csv-examples">
                    <code>Example 1: John Doe,ark1qxample123456789abcdef,0.00002</code>
                    <code>Example 2: Jane Smith,bitcoin:bc1qxy2kgdygjrsqtzq2n0yrf2493p83kkfjhx0wlh?ark=ark1qxample987654321,0.00005</code>
                  </div>
                </div>
              </div>
            </CollapsibleSection>

            <CollapsibleSection title="Transaction History Export">
              <div className="export-section">
                <p>Export all wallet transactions including deposits, withdrawals, and payroll operations.</p>
                <button 
                  className="export-button"
                  onClick={() => exportAllTransactions()}
                >
                  Export Transaction History
                </button>
              </div>
            </CollapsibleSection>

            <CollapsibleSection title="System Logs">
              <div className="logs-section">
                <div className="logs-header">
                  <button className="icon-button" onClick={downloadLogs} title="Download Logs">
                    📥
                  </button>
                </div>
                <textarea
                  rows="10"
                  cols="80"
                  readOnly
                  value={logs.join('\n')}
                />
              </div>
            </CollapsibleSection>
          </div>
        )}
      </div>

      <PasswordModal 
        isOpen={modalConfig.isOpen}
        onClose={() => setModalConfig({ isOpen: false, type: null })}
        onConfirm={modalConfig.type === 'send' ? handleSend : handleClaim}
        title={modalConfig.type === 'send' ? 'Send Virtual Bitcoin' : 'Claim Funds'}
        isSend={modalConfig.type === 'send'}
      />
    </div>
  );
}

export default Company;