import { nip19 } from 'nostr-tools';
import { bytesToHex, hexToBytes } from '@noble/hashes/utils';
import { sha256 } from '@noble/hashes/sha256';
import { xchacha20poly1305 } from '@noble/ciphers/chacha';
import { randomBytes } from '@noble/hashes/utils';
import { schnorr } from '@noble/curves/secp256k1';

// Get npub from private key hex
export const getPublicKeyFromPrivateKey = (privateKeyHex) => {
  try {
    if (typeof privateKeyHex !== 'string') {
      throw new Error('Private key must be a hex string');
    }
    
    const cleanHex = privateKeyHex.replace('0x', '');
    
    if (cleanHex.length !== 64) {
      throw new Error('Invalid private key length');
    }
    
    const privateKeyBytes = hexToBytes(cleanHex);
    const publicKeyBytes = schnorr.getPublicKey(privateKeyBytes);
    const publicKeyHex = bytesToHex(publicKeyBytes);
    const npub = nip19.npubEncode(hexToBytes(publicKeyHex));
    
    return npub;
  } catch (err) {
    console.error('Error getting public key:', err);
    console.error('Private key format:', {
      type: typeof privateKeyHex,
      length: privateKeyHex?.length,
      sample: privateKeyHex?.slice(0, 10)
    });
    return null;
  }
};

// Encrypt private key using XChaCha20-Poly1305
export const encrypt = (privateKeyHex, key) => {
  try {
    if (typeof key !== 'string') {
      throw new Error('Encryption key must be a hex string');
    }
    const cleanKey = key.replace('0x', '');
    
    const nonce = randomBytes(24);
    const encryptionKey = sha256(hexToBytes(cleanKey));
    const cipher = xchacha20poly1305(encryptionKey, nonce);
    
    // Convert private key hex to bytes directly
    const privateKeyBytes = hexToBytes(privateKeyHex);
    
    // Encrypt bytes
    const encrypted = cipher.encrypt(privateKeyBytes);
    
    // Combine nonce and encrypted data
    const result = new Uint8Array(nonce.length + encrypted.length);
    result.set(nonce);
    result.set(encrypted, nonce.length);
    
    return btoa(String.fromCharCode(...result));
  } catch (err) {
    console.error('Encryption error:', err);
    throw err;
  }
};

// Decrypt private key using XChaCha20-Poly1305
export const decrypt = (encryptedData, key) => {
  try {
    if (typeof key !== 'string') {
      throw new Error('Decryption key must be a hex string');
    }
    const cleanKey = key.replace('0x', '');
    
    const data = Uint8Array.from(atob(encryptedData), c => c.charCodeAt(0));
    const nonce = data.slice(0, 24);
    const encrypted = data.slice(24);
    
    const decryptionKey = sha256(hexToBytes(cleanKey));
    const cipher = xchacha20poly1305(decryptionKey, nonce);
    
    // Decrypt to bytes
    const decryptedBytes = cipher.decrypt(encrypted);
    
    // Convert bytes back to hex string
    return bytesToHex(decryptedBytes);
  } catch (err) {
    console.error('Decryption error:', err);
    throw err;
  }
}; 