let go;
let sdkFunctions = {
    init: null,
    receive: null,
    balance: null,
    unlock: null,
    lock: null,
    sendAsync: null,
    claim: null,
    getTransactionHistory: null
};

const WASM_URL = 'https://arkadewasm.bordalix.workers.dev?21';

// Add a delay function
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export const initSDK = async (privateKey) => {
    try {
        // Wait for Go to be available
        if (typeof window.Go === 'undefined') {
            throw new Error('Go is not defined. Make sure wasm_exec.js is loaded');
        }

        // Initialize Go WASM
        go = new window.Go();
        console.log('Fetching ark-sdk.wasm from remote...');
        
        try {
            console.log('Instantiating WASM...');
            const wasmResponse = await fetch(WASM_URL);
            const wasmBuffer = await wasmResponse.arrayBuffer();
            
            console.log('Compiling WASM...');
            const wasmModule = await WebAssembly.compile(wasmBuffer);
            
            console.log('Creating WASM instance...');
            const instance = await WebAssembly.instantiate(wasmModule, go.importObject);
            
            console.log('Starting WASM execution...');
            
            // Start WASM execution in a try-catch block
            try {
                go.run(instance);
            } catch (runError) {
                console.error('Error during WASM execution:', runError);
                throw runError;
            }

            // Wait for functions to be available
            const functions = ['init', 'receive', 'balance', 'unlock', 'lock', 'sendAsync', 'claim', 'getTransactionHistory'];
            await new Promise((resolve, reject) => {
                const checkFunctions = (attempts = 0) => {
                    if (attempts > 50) {
                        reject(new Error('Timeout waiting for WASM functions'));
                        return;
                    }
                    
                    const missingFunctions = functions.filter(f => !window[f]);
                    if (missingFunctions.length === 0) {
                        resolve();
                    } else {
                        setTimeout(() => checkFunctions(attempts + 1), 100);
                    }
                };
                checkFunctions();
            });

            // Assign SDK functions
            sdkFunctions.init = window.init;
            sdkFunctions.receive = window.receive;
            sdkFunctions.balance = window.balance;
            sdkFunctions.unlock = window.unlock;
            sdkFunctions.lock = window.lock;
            sdkFunctions.sendAsync = window.sendAsync;
            sdkFunctions.claim = window.claim;
            sdkFunctions.getTransactionHistory = window.getTransactionHistory;

            // Initialize wallet immediately if private key is provided
            if (privateKey) {
                const chain = "bitcoin";
                const walletType = "singlekey";
                const clientType = "rest";
                const aspUrl = localStorage.getItem('aspUrl');

                await sdkFunctions.init(
                    walletType,
                    clientType,
                    aspUrl,
                    privateKey,
                    '',
                    chain
                );
            }

            console.log('SDK initialized successfully');
            return true;
        } catch (wasmError) {
            console.error('WASM error:', wasmError);
            throw new Error(`WASM initialization failed: ${wasmError.message}`);
        }
    } catch (error) {
        console.error('Failed to initialize SDK:', error);
        throw error;
    }
};

// Export wrapper functions that check if the SDK is initialized
export const init = (...args) => {
    if (!sdkFunctions.init) throw new Error('SDK not initialized');
    return sdkFunctions.init(...args);
};

export const receive = (...args) => {
    if (!sdkFunctions.receive) throw new Error('SDK not initialized');
    return sdkFunctions.receive(...args);
};

export const balance = (...args) => {
    if (!sdkFunctions.balance) throw new Error('SDK not initialized');
    return sdkFunctions.balance(...args);
};

export const unlock = (...args) => {
    if (!sdkFunctions.unlock) throw new Error('SDK not initialized');
    return sdkFunctions.unlock(...args);
};

export const lock = (...args) => {
    if (!sdkFunctions.lock) throw new Error('SDK not initialized');
    return sdkFunctions.lock(...args);
};

export const sendAsync = (...args) => {
    if (!sdkFunctions.sendAsync) throw new Error('SDK not initialized');
    return sdkFunctions.sendAsync(...args);
};

export const getTransactionHistory = (...args) => {
    if (!sdkFunctions.getTransactionHistory) throw new Error('SDK not initialized');
    return sdkFunctions.getTransactionHistory(...args);
};

export const claim = (...args) => {
    if (!sdkFunctions.claim) throw new Error('SDK not initialized');
    return sdkFunctions.claim(...args);
}; 