import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import Company from './Company';
import Freelance from './Freelance';
import Cleanup from './Cleanup';
import './index.css';

// Check if wallet is set up
const isWalletSetup = () => {
  return localStorage.getItem('privateKeyHexEncrypted') && 
         localStorage.getItem('keySalt') && 
         localStorage.getItem('npub');
};

// Protected Route component
const ProtectedRoute = ({ children }) => {
  if (!isWalletSetup()) {
    return <Navigate to="/" replace />;
  }
  return children;
};

// Home Route component
const HomeRoute = () => {
  if (isWalletSetup()) {
    return <Navigate to="/company" replace />;
  }
  return <Login />;
};

const root = createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<HomeRoute />} />
      <Route path="/company" element={
        <ProtectedRoute>
          <Company />
        </ProtectedRoute>
      } />
      <Route path="/freelance" element={
        <ProtectedRoute>
          <Freelance />
        </ProtectedRoute>
      } />
      <Route path="/clean-danger" element={<Cleanup />} />
    </Routes>
  </BrowserRouter>
);
